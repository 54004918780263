import {
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
  OnChanges,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';

@Component({
  selector: 'qr-button',
  templateUrl: './qr-button.component.html',
  styleUrls: ['./qr-button.component.scss'],
  standalone: true,
  imports: [MatRippleModule, L10nTranslationModule],
})
export class QrButtonComponent implements OnChanges {
  style: string = '';
  selected = false;

  @Input() config: any;
  @Output() eventClick = new EventEmitter<any>(); // Evento que se dispara al hacer click
  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  /*
  ngOnChanges: Detecta que hubo un cambio en el componente
  */
  ngOnChanges(): void {
    if (this.config) {
      this.style = this.config.style;
      this.selected = this.config.selected;
      if (this.selected && this.config.changeStyleOnSelected) {
        this.style = this.config.styleOnSelected;
      }
    }
  }

  click(): void {
    // Si changeStyleOnSelected es true se cambia el style a styleOnSelected al clickearse
    if (this.config.changeStyleOnSelected == true) {
      if (this.selected == true) {
        this.config.selected = false;
        this.selected = false;
        this.style = this.config.style;
      } else {
        this.config.selected = true;
        this.selected = true;
        this.style = this.config.styleOnSelected;
      }
    }
    this.eventClick.emit(this.config); // Envio al componente padre el evento de click
  }
}
export interface IButtonConfig {
  // Creación dinámica de objetos de botones
  style: any;
  height: any;
  text: any;
  id: any;
  selected: boolean;
  isDisabled: boolean;
  changeStyleOnSelected: boolean;
  styleOnSelected: any;
}
